import slide1 from "../images/1.png";
import slide2 from "../images/2.png";
import slide3 from "../images/3.png";
import slide4 from "../images/4.png";

export const slider = [
    {
        id: 1,
        img: slide1
    },
    {
        id: 2,
        img: slide2
    },
    {
        id: 3,
        img: slide3
    },
    {
        id: 4,
        img: slide4
    }
]